import React from 'react';

import { getPathFromModel } from '../../utils/link';

import {
	Container,
	Content,
	Inner,
	Link,
	LinkContainer,
	LinkLabel,
	LinksWrapper,
	Message
} from './page-error.styles';

const PageError = ({ title, linksLabel, links }) => {
	return (
		<Container>
			<Inner>
				<Content>
					<Message>{title}</Message>
				</Content>
			</Inner>
			<LinkContainer>
				<LinkLabel>{linksLabel}</LinkLabel>
				{links && links.length > 0 &&
					<LinksWrapper>
						{links.map((link, index) => (
							<Link key={index} to={getPathFromModel({ model: link.model, slug: link.slug })}>{link.name || link.title}</Link>
						))}
					</LinksWrapper>
				}
			</LinkContainer>
		</Container>
	)
}

export default PageError;