import styled from "styled-components"


export const Wrapper = styled.main`
	position: relative;
`

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`