import styled from 'styled-components';

import { BREAKPOINT, COLUMNS, GUTTER, MARGIN, MAX_WIDTH, SIZE, SIZES, breakpoint, gutter, margin, maxWidth, mediaQuery, pixelate } from './metrics';

export const gridAtSize = (size) => `
	padding-left: ${margin(size)};
	padding-right: ${margin(size)};
`;

export const grid = () => {
	let styles = ``;

	SIZES.forEach(size => {
		if (size === SIZE.small) {
			styles += gridAtSize(size);
		}
		else {
			styles += `${mediaQuery[size]}{
				${gridAtSize(size)}
			}`
		}
	});

	return styles;
};

export const rowAtSize = (size, { reverse = false, wrap = true }) => `
	display: flex;
	margin-left: ${gutter(size, { multiplier: -0.5 })};
	margin-right: ${gutter(size, { multiplier: -0.5 })};
	flex-wrap: ${!!wrap ? `wrap` : `nowrap`};
	flex-direction: ${!!reverse ? `row-reverse` : `row`};
`;

const round = (num) => {
	return Math.floor(num * 1000000) / 1000000
}

export const row = (sizes = {}, _props) => {
	let styles = ``;
	let props = _props || null;

	SIZES.forEach(size => {
		props = sizes[size] ? { ...props, ...sizes[size] } : props;

		if (props) {
			if (size === SIZE.small) {
				styles += rowAtSize(size, props);
			}
			else {
				styles += `${mediaQuery[size]}{
					${rowAtSize(size, props)}
				}`
			}
		}
	});

	return styles;
};

export const columnAtSize = (size, { span, before = 0, after = 0, total = COLUMNS, reverse = false, order = 0 }, { direction = "ltr" }) => {
	let left = 0;
	let right = 0;

	if (direction === "rtl") {
		right = reverse ? after : before;
		left = reverse ? before : after;
	}
	else {
		left = reverse ? after : before;
		right = reverse ? before : after;
	}

	return `
		padding-left: ${gutter(size, { multiplier: 0.5 })};
		padding-right: ${gutter(size, { multiplier: 0.5 })};
		margin-left: ${left ? `calc((100% * ${round(1 / total)}) * ${left});` : `0;`}
		margin-right: ${right ? `calc((100% * ${round(1 / total)}) * ${right});` : `0;`}
		width: calc((100% * ${round(1 / total)}) * ${span ? span : total});
		flex: 0 0 calc((100% * ${round(1 / total)}) * ${span ? span : total});
		order: ${order};
	`;
}


export const column = (sizes = {}, { direction = "ltr" } = {}) => {
	let styles = ``;
	let props = null;


	SIZES.forEach(size => {
		props = sizes[size] ? { ...props, ...sizes[size] } : props;

		if (props) {
			if (size === SIZE.small) {
				styles += columnAtSize(size, props, { direction });
			}
			else {
				styles += `${mediaQuery[size]}{
					${columnAtSize(size, props, { direction })}
				}`
			}
		}
	});

	return styles;
}

export const imageAtSize = (size, { span, total = COLUMNS, margins = 0, gutters = 0 }) => {
	return `calc((((${size === SIZE.max ? maxWidth() : `100vw`} - ${margin(size, { multiplier: 2 })}) * ${round(1 / total)}) * ${span ? span : total}) - ${gutter(size)} ${margins > 0 ? ` + ${pixelate(margin(size, { pixelate: false, multiplier: margins }))}` : ''} ${gutters > 0 ? ` + ${pixelate(gutter(size, { pixelate: false, multiplier: gutters }))}` : ''})`;
}

export const columnWidthAsStyle = (size, { span, total = COLUMNS, multiplier = 1, adjustment }) => {
	return `calc((((((${size === SIZE.max ? maxWidth() : `100vw`} + ${gutter(size)} - ${margin(
		size,
		{ multiplier: 2 },
	)}) / ${total}) * ${span}) - ${gutter(size)}) * ${multiplier})${adjustment ? ` + ${adjustment}` : ``})`;
}

export const getImageSizes = (sizes, options) => {
	sizes = sizes || {};
	options = options || { defaultSize: "100vw" };

	let props = null;
	let result = options.defaultSize;
	let imageSize;

	SIZES.forEach(size => {
		props = sizes[size] ? { ...props, ...sizes[size] } : props;
		if (props) {
			imageSize = imageAtSize(size, props);
			result = `(min-width: ${breakpoint(size)}) ${imageSize}, ${result}`;
		}

	});

	return result;
}

const getBodyWidth = () => {
	if (typeof document !== 'undefined') {
		return document.body.clientWidth;
	}

	return null;
}

export const getColumnsWidth = (windowWidth, numColumns = 1, totalColumns = COLUMNS) => {
	let contentWidth = getBodyWidth();

	for (var i = SIZES.length - 1; i >= 0; i--) {

		if (BREAKPOINT[SIZES[i]] && windowWidth >= BREAKPOINT[SIZES[i]]) {
			return ((contentWidth + GUTTER[SIZES[i]] - (MARGIN[SIZES[i]] * 2)) / totalColumns) * numColumns;
		}
	}

	return ((contentWidth + GUTTER[SIZE.small] - (MARGIN[SIZE.small] * 2)) / totalColumns) * numColumns;
}

export const getGutterWidth = (windowWidth) => {

	let contentWidth = getBodyWidth();

	for (var i = SIZES.length - 1; i >= 0; i--) {

		if (BREAKPOINT[SIZES[i]] && windowWidth >= BREAKPOINT[SIZES[i]]) {
			return GUTTER[SIZES[i]];
		}
	}

	return GUTTER[SIZE.small];
}

export const getMarginWidth = (windowWidth) => {

	let contentWidth = getBodyWidth();

	for (var i = SIZES.length - 1; i >= 0; i--) {

		if (BREAKPOINT[SIZES[i]] && windowWidth >= BREAKPOINT[SIZES[i]]) {
			return MARGIN[SIZES[i]];
		}
	}

	return MARGIN[SIZE.small];
}


export const Grid = styled.div`
	${grid()}
`

export const Column = styled.div`
	${props => column({
	small: props.small,
	medium: props.medium,
	large: props.large,
	xlarge: props.xlarge,
	max: props.max
})}
`

export const Row = styled.div`
	${props => row({
	small: props.small,
	medium: props.medium,
	large: props.large,
	xlarge: props.xlarge,
	max: props.max
})}
`

export const getBeforeAbsolute = (size) => {
	return ((MAX_WIDTH + gutter('large', { pixelate: false })) / COLUMNS) * size;
};


